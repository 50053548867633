.Header {
    text-align: center;
    font-size: 30px;
    flex-basis: 100%;
}

.Image button{
    width: 15vw;
    border-radius: 20px;
    background-color: #6495ED;
    align-self: center;
    flex-basis: 33%;
    margin-left: 33vw;
    font-size: 48px;
    transition: .3s;
}

.Image button:hover {
    opacity: .65;
}
  
.Image {
    height: 960px;
    background-Image: url("../../pictures/reid.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media (max-width: 1280px){
      .Image {
          width: 1280px;
      }
  }

  .projectBox {
      border: 6px solid aquamarine;
      border-radius: 20px;
      width: 40vw;
      margin-right: 5vw;
      font-size: 30px;
      margin-bottom: 20px;
      background-color: rgb(255, 240, 240);
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      transition: .5s;
      height: 25%;
  }

  .projectBox p {
     /* transition: .6s; */
  }

  .projectBox p:first-of-type:hover {
      font-size: 42px;
      animation: color-change 1s infinite;
      font-weight: bold;
  }

  .projectBox:nth-of-type(even) {
    margin-left: 5vw;
  }

  .lame1 {
      font-size: 24px;
  }

  .lame2 {
      font-size: 16px;
  }

  @keyframes color-change {
    0% { color: rgb(204, 221, 54);}
    50% { color: rgb(231, 193, 68);}
    100% { color: rgb(204, 221, 54);}
  }

@media (min-height: 960px){
    .Image {
        height:100vh;
    }
}
